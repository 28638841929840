<template>
  <div id="app">
      1 {{cvtFrom}} = {{fmtAmt}} {{cvtTo}} <button @click="openModal"><svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="#000000" d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
    </svg></button>

      <CurrencySelect v-show="showModal" @close="closeModal" />
      
  </div>
</template>

<script>
import CurrencySelect from './components/CurrencySelect.vue'
import axios from 'axios'
import numeral from 'numeral'
import { store } from './store/store'

export default {
  name: 'converter',
  components: {
    CurrencySelect
  },
  data: () => ({
      fmtAmt: '1',
    showModal: false
  }),
  computed: {
      cvtTo() {
          if (window.local_currency) {
            return window.local_currency
          } else {
              return 'IDR'
          }
      },
      cvtFrom() {
          return store.getters.userCurrency
      }
  },
  methods: {
    openModal() {
        this.showModal = true
    },
    closeModal() {
        this.showModal = false
    },
    getRate() {
      let cvtString = this.cvtFrom + '_' + this.cvtTo
      const apiKey = "f04a3403ee5f705cc59e"
      const apiurl = "https://free.currconv.com/api/v7/convert?q="
      let url = apiurl + cvtString + '&compact=ultra&apiKey=' + apiKey
      axios.get(url)
        .then(res => {
            //console.log(res);
          this.cvtfactor = res.data[cvtString]
          this.fmtAmt = numeral(this.cvtfactor).format('0,0.00')
          // eslint-disable-next-line
          // console.log(this.cvtfactor)
      })
      let reverse = this.cvtTo + '_' + this.cvtFrom
      let url2 = apiurl + reverse + '&compact=ultra&apiKey=' + apiKey
      axios.get(url2)
        .then(res => {
            //console.log(res);
          let reverseFact = res.data[reverse]
          store.commit('setConversion', reverseFact)
          // eslint-disable-next-line
          // console.log(reverseFact)
      })
    }
  },
  created: function() {
      if (window.user_currency) {
         store.commit('userCurrency', window.user_currency)
      }
      // store.commit('userCurrency', this.cvtFrom)
      this.getRate()
  },
  watch: {
      cvtFrom: function () {
          this.getRate()
      }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: inline;
}
    button { border: none; background-color: transparent; }
</style>

<template>
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">

              <div class="modal-header">
                <h3>Select your currency</h3>
              </div>

              <div class="modal-body">
                <slot name="body">
                    <v-select v-model="current" @input="setCurrent" :options="currencies"></v-select>
                </slot>
              </div>

              <div class="modal-footer">
                <slot name="footer">
                  <button class="modal-default-button" @click="$emit('close')">
                    OK
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
</template>

<script>
import {fireDb} from '../plugins/firebase'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { store } from '../store/store'
import axios from 'axios'

export default {
  name: 'currencySelect',
  components: {
      vSelect
  },
  data: () => ({
      currencies: [],
      current: 'USD'
  }),
   methods: {
      close() {
        this.$emit('close');
      },
      setCurrent: value => {
          // this.current = value.id
          store.commit('setCurrency', value.id)
          // eslint-disable-next-line
          //console.log(value.id)
          axios.get('http://localhost/sus/sus19/update-pref?usercurrency=' + value.id)
            .then(res => {
              // eslint-disable-next-line
              console.log(res)
          })
      }
   },
  created: function() {
      fireDb.collection('currencies').get()
        .then(snapshot => {
          var tmpC = []
          snapshot.forEach(doc => {
              tmpC.push({ id: doc.data().id, label: doc.data().currencyName })
          })
          this.currencies = tmpC
      })
      this.current = store.getters.userCurrency
  }
}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #064273;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        userCurrency: 'USD',
        cvtFactor: 1.0
    },
    mutations: {
        setCurrency(state, userCurrency) {
            state.userCurrency = userCurrency
        },
        setConversion(state, conversion) {
            state.cvtFactor = conversion
        }
    },
    getters: {
        userCurrency: state => state.userCurrency,
        cvtFactor: state => state.cvtFactor
    }
})